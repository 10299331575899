// first import functions
@import '~bootstrap/scss/functions';

//overrides of BS variables here
$white: #ffffff;

$primary: #3f2562;
$secondary: #6d4f9c;
$success: #6ab53c;
$danger: #d43441;
$warning: #ffe66d;
$info: #ed6a6d;
$light: #c9b8db;
$dark: #181222;

$headings-font-weight: 900;

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

$font-family-base: 'Lato', sans-serif;
$font-weight-normal: 300;
$font-weight-base: $font-weight-normal;
// $headings-font-family: '', sans-serif;

@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/utilities';

// changes to BS utilities here

@import '~bootstrap/scss/utilities/api';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/bootstrap';

// all other custom styles below this

// general styles
body {
  overscroll-behavior: contain;
}

b {
  font-weight: 900;
}

strong {
  font-weight: 900;
}

.reading-text {
  font-family: 'Merriweather', serif;
  hyphens: auto;
}

.monospace-text {
  font-family: 'Courier Prime', 'Courier', monospace;
  font-size: 14px;
}

.page-main-container {
  @extend .m-0;
  @extend .p-0;
  @extend .p-sm-1;
  @extend .p-lg-3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

.nav-link {
  text-transform: uppercase;
  font-size: 0.9em;
}

.shx-helptext {
  @extend .p-1;
  @extend .pe-3;

  .btn-close {
    @extend .p-1;
  }
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus:not(:disabled):not(.disabled),
.btn:focus,
.btn:active,
.btn:hover {
  box-shadow: none !important;
  outline: 0;
  background-color: transparent;
  color: var(--bs-primary);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.btn:focus,
.btn:active,
.btn:hover {
  box-shadow: none !important;
  outline: 0;
  background-color: transparent;
  color: var(--bs-secondary);
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success:active:focus:not(:disabled):not(.disabled),
.btn:focus,
.btn:active,
.btn:hover {
  box-shadow: none !important;
  outline: 0;
  background-color: transparent;
  color: var(--bs-success);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger:active:focus:not(:disabled):not(.disabled),
.btn:focus,
.btn:active,
.btn:hover {
  box-shadow: none !important;
  outline: 0;
  background-color: transparent;
  color: var(--bs-danger);
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning:active:focus:not(:disabled):not(.disabled),
.btn:focus,
.btn:active,
.btn:hover {
  box-shadow: none !important;
  outline: 0;
  background-color: transparent;
  color: var(--bs-warning);
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info:active:focus:not(:disabled):not(.disabled),
.btn:focus,
.btn:active,
.btn:hover {
  box-shadow: none !important;
  outline: 0;
  background-color: transparent;
  color: var(--bs-info);
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light:active:focus:not(:disabled):not(.disabled),
.btn:focus,
.btn:active,
.btn:hover {
  box-shadow: none !important;
  outline: 0;
  background-color: transparent;
  color: var(--bs-light);
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark:active:focus:not(:disabled):not(.disabled),
.btn:focus,
.btn:active,
.btn:hover {
  box-shadow: none !important;
  outline: 0;
  background-color: transparent;
  color: var(--bs-dark);
}

@import './calendar';
@import './graphs';
@import './markdown';
@import './reading-plan';
@import './prayer';
@import './passage';
@import './plan';
