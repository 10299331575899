$graph-colors: $primary $success $danger $warning $info $dark $secondary;
$graph-text-colors: $white $white $white $dark $white $white $white;

@each $current-color in $graph-colors {
  $i: index($graph-colors, $current-color);
  .pie-slice-#{$i - 1} {
    fill: $current-color;
  }

  .shx-area-#{$i - 1} .recharts-area-area {
    fill: $current-color;
  }
  .shx-area-#{$i - 1} .recharts-area-curve {
    stroke: $current-color;
  }

  .shx-line-#{$i - 1} .recharts-line-curve {
    stroke: $current-color;
  }

  .shx-bar-#{$i - 1} .recharts-bar-rectangle {
    fill: $current-color;
  }

  .shx-tree-rect-#{$i - 1} {
    stroke: $white;
    fill: $current-color;
  }
  .shx-tree-text-#{$i - 1} {
    fill: nth($graph-text-colors, $i);
  }

  .legend-item-#{$i - 1} {
    .recharts-surface {
      fill: $current-color;
    }

    span {
      color: $current-color;
    }
  }

  .colour-chooser-btn-#{$i - 1} {
    background-color: $current-color;
    color: nth($graph-text-colors, $i);
    border-color: nth($graph-text-colors, $i);
  }
}
