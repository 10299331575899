.passage-card {
  @extend .m-2;
  @extend .shadow;

  .input-group-text {
    @extend .border-0;
    @extend .bg-white;
  }

  .form-control-plaintext {
    width: auto;
  }

  .card-body {
    @extend .d-flex;
    @extend .flex-column;

    .card-text {
      @extend .user-select-none;
      cursor: pointer;
    }
  }

  .card-footer {
    @extend .d-flex;
    @extend .justify-content-end;
    @extend .p-1;
    @extend .align-items-center;
    @extend .bg-transparent;

    button {
      @extend .btn-outline-secondary;
      @extend .link-secondary;
    }
  }

  .selected-passage {
    @extend .bg-secondary-subtle;
  }
}

.passage-card-empty {
  @extend .rounded-0;
  @extend .border-0;
  @extend .shadow-none;
  @extend .bg-primary-subtle;
  @extend .m-0;

  .input-group-text {
    @extend .border-0;
    @extend .bg-primary-subtle;
  }

  .form-control-plaintext {
    width: auto;
  }

  .card-body {
    @extend .d-flex;
    @extend .flex-column;
    @extend .p-1;

    strong {
      @extend .text-primary;
    }

    .card-text {
      @extend .user-select-none;
      cursor: auto;
    }
  }

  .card-footer {
    @extend .d-flex;
    @extend .justify-content-end;
    @extend .p-1;
    @extend .align-items-center;
    @extend .bg-transparent;

    a {
      @extend .link-primary;
    }

    button {
      @extend .btn-outline-primary;
      @extend .link-primary;
    }
  }
}

.passage-card-temp {
  @extend .passage-card;
  @extend .bg-dark-subtle;

  .input-group-text {
    @extend .bg-dark-subtle;
  }

  button {
    @extend .btn-outline-dark;
    @extend .link-dark;
  }

  .card-footer {
    a {
      @extend .link-dark;
    }

    button {
      @extend .btn-outline-dark;
      @extend .link-dark;
    }
  }
}

.passage-card-selected {
  @extend .passage-card;
  @extend .bg-secondary-subtle;

  .input-group-text {
    @extend .bg-secondary-subtle;
  }

  strong {
    @extend .text-primary;
  }

  .card-footer {
    a {
      @extend .link-primary;
    }

    button {
      @extend .btn-outline-primary;
      @extend .link-primary;
    }
  }
}
